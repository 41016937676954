<template>
	<div class="home">
		<div class="home-1" style="padding-bottom: 51px;background-color: #6b00AF">
			<img src="https://khome2.tuzuu.com/vita/chome_1.png?v=2" />

			<div class="home-desc">
				<div class="title"> 轻松二步，让健康简单起来</div>
				<HomeDesc />
			</div>
		</div>

		<div class="home-2">
			<!--<img src="https://khome2.tuzuu.com/vita/chome_2.png">-->
			<div class="title">用营养科学定制您的健康</div>
			<div class="my-swipe">
				<van-swipe :autoplay="3000" indicator-color="white">
					<van-swipe-item>
						<div class="my-swipe-item">
							<!--用户-->
							<div class="flex" style="margin-bottom:10px">
								<van-image round width="50px" height="50px" src="https://khome2.tuzuu.com/vita/preson_1.png" />
								<div class="flex-column-center person" style="align-items: end">
									<div class="name">韩妈妈</div>
									<div class="info">
										<span>49岁</span>
										<span>步入中老年</span>
									</div>
								</div>
							</div>
							<!--标签-->
							<div class="flex-warp tag-list">
								<div class="tag-item">脱发厉害</div>
								<div class="tag-item">免疫力低下</div>
								<div class="tag-item">关节酸痛</div>
								<div class="tag-item">三高问题</div>
								<div class="tag-item">……</div>
							</div>
							<div class="title">通过FFQs和精准科学的检测</div>
							<div class="h4 flex">韩妈妈的定制营养包</div>
							<div class="packge-list flex-warp">
								<div class="packge-item flex-center" v-for="(item,index) in packge[0]"
									:style="{alignItems:index == 5 ? 'center' : ''}">
									<div class="packge-item-1" v-if="index != 5"></div>
									<div class="packge-item-2 flex-column-center" v-if="index !=5"
										:style="{backgroundImage:`url(${item.url})`}">
										<div class="item-1">{{ item.title }}</div>
										<div class="item-2">{{ item.desc }}</div>
									</div>
									<div class="item-3" v-if="index == 5">……</div>
								</div>
							</div>
							<div class="h4 flex" style="font-size: 16px;margin-top: 5px">韩妈妈:</div>
							<div class="tell-info">
								<img src="https://khome2.tuzuu.com/vita/text_1.png">
							</div>
						</div>
					</van-swipe-item>
					<van-swipe-item>
						<div class="my-swipe-item">
							<!--用户-->
							<div class="flex" style="margin-bottom:10px">
								<van-image round width="50px" height="50px" src="https://khome2.tuzuu.com/vita/preson_2.png" />
								<div class="flex-column-center person" style="align-items: end">
									<div class="name">晓晓妈</div>
									<div class="info">
										<span>29岁</span>
										<span>刚生完宝宝</span>
									</div>
								</div>
							</div>
							<!--标签-->
							<div class="flex-warp tag-list">
								<div class="tag-item">工作压力大，有时会熬夜</div>
								<div class="tag-item">丢三落四</div>
								<div class="tag-item">焦虑</div>
								<div class="tag-item">有时候会头晕</div>
								<div class="tag-item">……</div>
							</div>
							<div class="title">通过FFQs和精准科学的检测</div>
							<div class="h4 flex">晓晓妈的定制营养包</div>
							<div class="packge-list flex-warp">
								<div class="packge-item flex-center" v-for="(item,index) in packge[1]"
									:style="{alignItems:index == 5 ? 'center' : ''}">
									<div class="packge-item-1" v-if="index != 5"></div>
									<div class="packge-item-2 flex-column-center" v-if="index !=5"
										:style="{backgroundImage:`url(${item.url})`}">
										<div class="item-1">{{ item.title }}</div>
										<div class="item-2">{{ item.desc }}</div>
									</div>
									<div class="item-3" v-if="index == 5">……</div>
								</div>
							</div>
							<div class="h4 flex" style="font-size: 16px;margin-top: 5px">晓晓妈:</div>
							<div class="tell-info">
								<img src="https://khome2.tuzuu.com/vita/text_2.png">
							</div>
						</div>
					</van-swipe-item>
					<van-swipe-item>
						<div class="my-swipe-item">
							<!--用户-->
							<div class="flex" style="margin-bottom:10px">
								<van-image round width="50px" height="50px" src="https://khome2.tuzuu.com/vita/preson_3.png" />
								<div class="flex-column-center person" style="align-items: end">
									<div class="name">刘先生</div>
									<div class="info">
										<span>25岁</span>
										<span>步入职场2,3年</span>
									</div>
								</div>
							</div>
							<!--标签-->
							<div class="flex-warp tag-list">
								<div class="tag-item">是从熬夜</div>
								<div class="tag-item">一直在办公室久坐，腰酸背痛</div>
								<div class="tag-item">对着电脑，眼睛发酸</div>
								<div class="tag-item">……</div>
							</div>
							<div class="title">通过FFQs和精准科学的检测</div>
							<div class="h4 flex">刘先生的定制营养包</div>
							<div class="packge-list flex-warp">
								<div class="packge-item flex-center" v-for="(item,index) in packge[2]"
									:style="{alignItems:index == 5 ? 'center' : ''}">
									<div class="packge-item-1" v-if="index != 5"></div>
									<div class="packge-item-2 flex-column-center" v-if="index !=5"
										:style="{backgroundImage:`url(${item.url})`}">
										<div class="item-1">{{ item.title }}</div>
										<div class="item-2">{{ item.desc }}</div>
									</div>
									<div class="item-3" v-if="index == 5">……</div>
								</div>
							</div>
							<div class="h4 flex" style="font-size: 16px;margin-top: 5px">刘先生：</div>
							<div class="tell-info">
								<img src="https://khome2.tuzuu.com/vita/text_3.png">
							</div>
						</div>
					</van-swipe-item>
				</van-swipe>
			</div>
		</div>

		<div class="home-3 flex-column-center">
			<!--<img src="https://khome2.tuzuu.com/vita/home_0.png?v=1">-->
			<div class="home-3-box">
				<img src="https://khome2.tuzuu.com/vita/home_13.png">
				<div class="context-1">
					<div class="context-1-left">
						<div class="title">传统保健品</div>
						<ul style="margin-bottom: 15px">
							<li class="flex">品牌杂乱不知道如何选择</li>
							<li class="flex">到底吃什么，怎么吃</li>
							<li class="flex">品质不一，多吃盲从</li>
							<li class="flex">到底有没有效果，不知道</li>
						</ul>
						<div class="end-info">
							<p>约花费每月600元左右</p>
							<p> 关键不知道效果</p>
						</div>
					</div>
					<div class="context-1-right">
						<div class="title">UitaUita-每日营养专家</div>
						<ul>
							<li>
								<div>每天一小包，简单方便</div>
							</li>
							<li>
								<div>科学检测每日所需，针对性改善你的身体</div>
							</li>
							<li>
								<div>通过国际公认的GMP、FDA和SGS认证，从原材料开始严格把控</div>
							</li>
							<li>
								<div>专业营养师1v1实时追踪改善效果</div>
							</li>
						</ul>
						<div class="end-info"
							style="color: white;font-size: 16px;font-weight: 700;line-height: 22px;letter-spacing: 0.5px;">
							<p>每月平均仅400元</p>
							<p>让健康简简单单</p>
						</div>
					</div>
				</div>
				<div style="background-color: #401963;border-radius: 50px">
					<img src="https://khome2.tuzuu.com/vita/home_14.png">
					<div class="context-2">
						<div class="context-2-item flex-between" v-for="(item,index) in list" :key="index"
							@click="href(item.url)">
							<div style="display: flex">
								<div class="left-color" :style="{backgroundColor:item.color}"></div>
								<div class="left-info flex-column-center">
									<div class="left-info-1">{{item.title}}</div>
									<div class="left-info-2">
										<p v-for="(infoItem,index) in item.info" :key="index">{{infoItem}}</p>
									</div>
								</div>
							</div>
							<img src="https://khome2.tuzuu.com/vita/left2.png">
						</div>
					</div>
				</div>

			</div>
			<!--<div class="notify-item" style="background-color: #FF0061">吃什么？怎么吃</div>-->
			<!--<div class="notify-item" style="background-color: #FF34A0">我的身体为什么会变差？</div>-->
			<!--<div class="notify-item" style="background-color: #9E3DF7">如何用科学的方式寻找最适合自己的营养方案？</div>-->

			<div class="small-title flex">本期活动</div>
			<div class="small-info">打工人“回血”健康营</div>
			<img src="https://khome2.tuzuu.com/vita/home_15.png" style="width: calc(100% - 20px);margin: 0 auto;margin-top: 15px;">
		</div>

		<div class="bottom-btn flex-between" v-if="!isSuccess">
			<div class="left">
				<p>开启<strong>7</strong>天营养师1对1健康改善</p>
				<p>原价 <s>¥299</s></p>
			</div>
			<div class="right" @click="handHref('end_consult')">
				<p>点击报名</p>
				<p>限时免费</p>
			</div>
		</div>

		<div class="bottom-btn flex-between" v-else>
			<div class="left" style="font-size: 16px">
				你已成功报名
			</div>
			<div class="right" style="padding: 14px" @click="handHref('consult')">
				联系我的营养顾问
			</div>
		</div>
	</div>
</template>

<script>
	import HomeDesc from "../../components/HomeDesc";

	export default {
		name: "",
		components: {
			HomeDesc
		},
		created() {
			this.$request.get(
				"get_activity_status"
			).then(res => {
				if (res.code === 1) {
					if (res.data.in_activity == 1) {
						this.isSuccess = true
					} else {
						this.isSuccess = false
					}
				}
				console.log(res)
			})
		},
		data() {
			return {
				packge: [
					[{
							title: '辅酶Q10',
							desc: '心脏发动机',
							url: 'https://khome2.tuzuu.com/vita/home_4.png'
						},
						{
							title: '维生素C',
							desc: '免疫力Plus',
							url: 'https://khome2.tuzuu.com/vita/home_8.png'
						},
						{
							title: '钙镁剂',
							desc: '强筋健骨',
							url: 'https://khome2.tuzuu.com/vita/home_9.png'
						},
						{
							title: '维生素B族',
							desc: '活力慢慢',
							url: 'https://khome2.tuzuu.com/vita/home_10.png'
						},
						{
							title: 'Omega-3',
							desc: '减压护心',
							url: 'https://khome2.tuzuu.com/vita/home_3.png'
						},
						{
							title2: '……'
						}
					],
					[{
							title: 'Omega-3',
							desc: '减压护心',
							url: 'https://khome2.tuzuu.com/vita/home_3.png'
						},
						{
							title: '辅酶Q10',
							desc: '心脏发动机',
							url: 'https://khome2.tuzuu.com/vita/home_4.png'
						},
						{
							title: '假马齿苋',
							desc: '健脑高手',
							url: 'https://khome2.tuzuu.com/vita/home_5.png'
						},
						{
							title: '铁剂',
							desc: '满血复活',
							url: 'https://khome2.tuzuu.com/vita/home_6.png'
						},
						{
							title: '葡萄籽精华',
							desc: '抗衰奥秘',
							url: 'https://khome2.tuzuu.com/vita/home_7.png'
						},
						{
							title2: '……'
						}
					],
					[{
							title: '乳蓟提取物',
							desc: '护肝Plus',
							url: 'https://khome2.tuzuu.com/vita/home_11.png'
						},
						{
							title: '越橘浓缩精华',
							desc: '点亮视觉',
							url: 'https://khome2.tuzuu.com/vita/home_12.png'
						},
						{
							title: '维生素B族',
							desc: '活力满满',
							url: 'https://khome2.tuzuu.com/vita/home_10.png'
						},
						{
							title: '维生素C',
							desc: '免疫力Plus',
							url: 'https://khome2.tuzuu.com/vita/home_8.png'
						},
						{
							title: '钙镁剂',
							desc: '强筋健骨',
							url: 'https://khome2.tuzuu.com/vita/home_9.png'
						},
						{
							title2: '……'
						}
					]
				],
				list: [{
						title: '质量认证',
						color: '#FD4D0E',
						info: ["原料、生产、包装", " 通过全球公认的SGS、GMP和FDA"],
						url: '/quality'
					},
					{
						title: '严谨科学',
						color: '#6C75DF',
						info: ["通过国际通用的FFQs获取整体状态", " 透过精准科学检测给您最适合的营养方案"],
						url: '/science'
					},
					{
						title: '专业团队',
						color: '#43C25B',
						info: ["专业营养师顾问", " 基于大数据分析，为您定制最适合你的营养包"],
						url: '/team'
					}
				],
				isSuccess: false
			}
		},
		methods: {
			href(url) {
				this.$router.push({
					path: url
				})
			},
			handHref(url) {
				if (url == 'end_consult') {
					let toast = this.$toast.loading({
						message: '报名中',
						forbidClick: true,
					});
					window._czc.push(['_trackEvent', '首页', '点击报名', ])
					this.$request.get(
						'/apply_activity'
					).then(res => {
						if (res.code == 1) {
							console.log(res)
							toast.clear()
							 if(!window.localStorage.getItem("timer")){
								  let overDate = new Date().getTime() + 24 * 60 * 60 * 1000;
								   window.localStorage.setItem("timer",overDate)
							 }
							 this.$router.push({
							 	path: `my/${url}`
							 })
						}
					})
				}else{
					this.$router.push({
						path: `my/${url}`
					})
				}
		
			},

		}
	}
</script>

<style scoped lang="less">
	.home {
		background-color: #F3F3F4;
		width: 100%;
		min-height: 100vh;
		font-size: 16px;
		padding-bottom: 77px;

		.home-1 {
			position: relative;

			>img {
				width: 100%;
				display: block;
			}

			.home-desc {
				width: calc(100% - 20px);
				background-color: white;
				position: absolute;
				bottom: 20px;
				left: 10px;
				border-radius: 10px;
				padding: 25px 20px;
				box-sizing: border-box;

				.title {
					font-size: 20px;
					text-align: center;
					color: #3F3F3F;
					margin-bottom: 25px;
					box-sizing: border-box;
					font-weight: 900;
					font-family: HYQiHei-GEW, HYQiHei;
				}
			}
		}

		.home-2 {
			width: calc(100% - 20px);
			margin: 0 auto;
			//height: 740px;
			padding: 0px 0px;
			padding-bottom: 20px;
			border-radius: 10px;
			box-sizing: border-box;
			margin-top: 20px;
			position: relative;
			background-color: #6b00AF;

			>img {
				width: 100%;
				display: block;
			}

			>.title {
				font-size: 20px;
				line-height: 25px;
				font-weight: 900;
				color: white;
				top: 30px;
				left: 0;
				padding: 0px 10px;
				box-sizing: border-box;
				width: 100%;
				text-align: center;
				position: absolute;
			}

			//轮播图
			.my-swipe {
				//position: absolute;
				//top: 70px;
				//left: 10px;

				padding-top: 70px;
				width: calc(100%);

				/deep/ .van-swipe {
					padding-bottom: 25px;
				}

				/deep/ .van-swipe__indicators {
					bottom: 0px !important;
				}
			}

			.van-swipe-item {
				padding: 0px 10px;
				box-sizing: border-box;
				width: 100%;
			}

			.my-swipe-item {
				width: 100%;
				background-color: #FDFDFD;
				border-radius: 10px;
				padding: 15px 15px 30px 15px;
				box-sizing: border-box;

				//信息
				.person {
					margin-left: 15px;

					.name {
						font-size: 15px;
						color: #3F3F3F;
						font-weight: 700;
						margin-bottom: 6px;
					}

					.info {
						font-size: 14px;
						color: #666666;
						font-weight: 700;
					}
				}

				.tag-list {
					margin-top: 10px;
					justify-content: end;

					.tag-item {
						font-size: 12px;
						color: #FFFFFF;
						font-weight: 700;
						background-color: #FF35A1;
						padding: 4px 8px;
						border-radius: 5px;
						margin-right: 7px;
						margin-top: 10px;

					}
				}

				.title {
					font-size: 15px;
					color: #3F3F3F;
					font-weight: 700;
					margin-top: 20px;
					justify-content: center;
					align-items: center;
					display: flex;

					&:before {
						content: '';
						margin-right: 10px;
						width: 25px;
						height: 1px;
						background-color: black;
						display: inline-block;
						line-height: 7px;
					}

					&:after {
						content: '';
						margin-left: 10px;
						width: 25px;
						height: 1px;
						background-color: black;
						display: inline-block;
						line-height: 7px;
					}
				}

				.h4 {
					margin-top: 10px;
					font-size: 15px;
					color: #3F3F3F;
					margin-bottom: 12px;

					&:before {
						content: '';
						display: block;
						width: 5px;
						height: 20px;
						background-color: #6D00AE;
						border-radius: 18px;
						margin-right: 10px;
					}
				}

				.packge-list {
					justify-content: space-between;
					box-sizing: border-box;

					.packge-item {
						width: 48%;
						height: 60px;
						align-items: end;
						box-sizing: border-box;
						border-radius: 8px;
						margin-bottom: 10px;

						.packge-item-1 {
							height: 100%;
							width: 18px;
							//border: 1px solid #F4F4F4;
							background-color: #E8E8E8;
							//border-right: none;
							box-sizing: border-box;
							border-radius: 8px 0px 0px 8px;
						}

						.packge-item-2 {
							width: calc(100% - 18px);
							height: 100%;
							background-repeat: no-repeat;
							background-size: cover;
							margin-bottom: 10px;
							background-position: center;
							justify-content: center;
							align-items: end;
							padding-left: 14px;
							box-sizing: border-box;
							border-radius: 0px 8px 8px 0px;
						}

						.item-1 {
							font-size: 14px;
							color: #FFFFFF;
							font-weight: 700;
							margin-bottom: 6px;

						}

						.item-2 {
							font-size: 11px;
							color: #F3F3F4;
						}

						.item-3 {
							font-size: 16px;
							color: #292C2F;
						}
					}
				}

				.tell-info {
					box-sizing: border-box;
					padding-left: 10px;

					>img {
						width: 100%;
					}
				}

			}
		}

		.home-3 {
			position: relative;
			background-color: #6D00AF;
			margin-top: 20px;
			align-items: end;
			padding-bottom: 25px;

			>img {
				width: 100%;
				display: block;
				margin-top: 200px;
				margin-bottom: 15px;
			}

			.notify-item {
				color: white;
				font-size: 14px;
				font-weight: 500;
				width: fit-content;
				padding: 5px 7px;
				text-align: left;
				border-radius: 5px;
				margin-left: 17px;
				margin-bottom: 10px;
			}
		}

		.home-3-box {
			//position: absolute;
			width: calc(100% - 20px);
			//left: 0;
			//top: 0;
			//padding: 0px 10px;
			margin: 0 auto;
			padding-top: 20px;
			box-sizing: border-box;

			>img {
				width: 100%;
				display: block;
			}

		}

		.context-1 {

			background-color: #F3F3F4;
			border-radius: 0px 0px 10px 10px;
			padding: 17px 8px;
			display: flex;
			align-items: flex-end;
			justify-content: space-around;
			margin-bottom: 20px;

			.end-info {
				font-size: 14px;
				color: #292C2F;
				line-height: 20px;
				padding: 14px 12px;
				padding-top: 0px;
				white-space: nowrap;
				font-weight: 500;
			}

			.context-1-left {
				width: 48%;
				padding: 0px 5px;
				background-color: #FDFDFD;
				border-radius: 5px;
				box-sizing: border-box;

				.title {
					font-size: 14px;
					font-weight: 700;
					padding-top: 16px;
					color: #3F3F3F;
					width: fit-content;
					margin: 0 auto;
					padding-bottom: 1px;
					border-bottom: 1px solid #292C2F;
				}

				ul {
					font-size: 12px;
					font-weight: 700;
					color: #666666;
					margin: 10px 0px;
					border-bottom: 2px solid #292C2F;

					li {
						display: flex;
						align-items: baseline;
						margin-bottom: 12px;

						&::before {
							content: '';
							display: inline-block;
							width: 6px;
							height: 6px;
							border: 1px solid #292C2F;
							border-radius: 50%;
							margin-right: 5px;
						}
					}
				}

			}

			.context-1-right {
				width: 48%;
				padding: 0px 5px;
				box-sizing: border-box;
				background-color: #FD4D0E;
				border-radius: 5px;

				.title {
					font-size: 14px;
					color: #FFFFFF;
					font-weight: 700;
					text-align: center;
					width: fit-content;
					margin: 0 auto;
					padding-top: 16px;
					padding-bottom: 5px;
					border-bottom: 1px solid #FFFFFF;
				}

				ul {
					font-size: 12px;
					color: #FCFCFC;
					margin: 10px 0px;
					border-bottom: 2px solid #FFFFFF;

					li {
						display: flex;
						align-items: baseline;
						margin-bottom: 12px;

						&::before {
							content: '';
							display: inline-block;
							width: 6px;
							height: 6px;
							background: #FFFFFF;
							border-radius: 50%;
							margin-right: 5px;
						}

						div {
							width: 100%;
						}
					}
				}
			}
		}

		.context-2 {
			margin-top: 13px;

			.context-2-item {
				margin-bottom: 13px;
				background-color: white;
				border-radius: 7px;

				.left-color {
					width: 15px;
					border-radius: 6px;
					background-color: #FD4D0E;
				}

				.left-info {
					margin-left: 15px;
					height: 90px;
					justify-content: center;
					align-items: end;

					.left-info-1 {
						font-size: 16px;
						color: #292C2F;
						font-weight: 700;
						width: fit-content;
						padding-bottom: 2px;
						border-bottom: 2px solid #5F5F5E;
						margin-bottom: 4px;
					}

					.left-info-2 {
						font-size: 12px;
						color: #666666;
						line-height: 20px;
					}
				}

				>img {
					width: 18px;
					margin-right: 30px;

				}

				&:last-of-type {
					margin-bottom: 0px;
				}
			}
		}

		.small-title {
			font-size: 14px;
			font-weight: 700;
			color: white;
			margin-left: 15px;
			margin-top: 20px;

			&::before {
				display: block;
				content: '';
				width: 15px;
				height: 15px;
				background-color: white;
				margin-right: 7px;
				border-radius: 50%;
			}
		}

		.small-info {
			font-size: 16px;
			font-weight: 700;
			color: #FFFFFF;
			margin-left: 36px;
			margin-top: 12px;
		}

		.bottom-btn {
			position: fixed;
			width: 100%;
			background-color: white;
			height: 77px;
			left: 0;
			bottom: 0;
			z-index: 6989996;
			padding: 0px 15px;
			box-sizing: border-box;
			border-top: 1px solid #cfcfcf;

			.left {
				font-size: 15px;

				color: #292C2F;
				padding: 8px 0px;
				box-sizing: border-box;

				p:last-of-type {
					text-align: right;
				}

				strong {
					font-size: 33px;
				}

				s {
					font-size: 21px;
					margin-right: 5px;
					font-weight: 700;
					font-family: HYQiHei-GZS, HYQiHei;
				}
			}

			.right {
				padding: 5px 28px;
				background-color: #FE4B12;
				border-radius: 5px;
				font-size: 17px;
				font-weight: 700;
				color: white;
			}
		}

	}
</style>
